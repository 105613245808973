<template>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="flex flex-col md:flex-row my-10">
            <div class="w-48 mr-4">
                <nav>
                    <router-link :to="{name: 'accountSettings'}" tag="a" class="group flex items-center px-3 py-2 text-md leading-5 font-medium text-gray-200 rounded-md hover:text-jiruto-zotSecondary focus:outline-none focus:text-jiruto-zotSecondary transition ease-in-out duration-100 ">
                        <span class="truncate">
                          Settings
                        </span>
                    </router-link>
                    <router-link :to="{name: 'accountSubscription'}" tag="a" class="mt-2 group flex items-center px-3 py-2 text-md leading-5 font-medium text-gray-200 rounded-md hover:text-jiruto-zotSecondary focus:outline-none focus:text-jiruto-zotSecondary transition ease-in-out duration-100">
                        <span class="truncate">
                          Subscription
                        </span>
                    </router-link>
<!--                    <a href="#" class="mt-2 group flex items-center px-3 py-2 text-md leading-5 font-medium text-gray-200 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 focus:text-gray-900 transition ease-in-out duration-100">-->
<!--                        <span class="truncate">-->
<!--                          Receipts-->
<!--                        </span>-->
<!--                    </a>-->
<!--                    <a href="#" class="mt-2 group flex items-center px-3 py-2 text-md leading-5 font-medium text-gray-200 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 focus:text-gray-900 transition ease-in-out duration-100">-->
<!--                        <span class="truncate">-->
<!--                          Stats-->
<!--                        </span>-->
<!--                    </a>-->
<!--                    <a href="#" class="mt-2 group flex items-center px-3 py-2 text-md leading-5 font-medium text-gray-200 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 focus:text-gray-900 transition ease-in-out duration-100">-->
<!--                        <span class="truncate">-->
<!--                          Documents-->
<!--                        </span>-->
<!--                    </a>-->
<!--                    <a href="#" class="mt-2 group flex items-center px-3 py-2 text-md leading-5 font-medium text-gray-200 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 focus:text-gray-900 transition ease-in-out duration-100">-->
<!--                        <span class="truncate">-->
<!--                          Reports-->
<!--                        </span>-->
<!--                    </a>-->
                </nav>
            </div>
            <div class="w-full">
                <router-view/>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "Account"
    }
</script>

<style scoped>
    .router-link-active{
        @apply text-jiruto-zotSecondary border-jiruto-zotSecondary outline-none
    }
</style>
